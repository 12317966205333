import { Badge, Button, Icon, Menu, notification as antdNotification } from 'antd';
import React, { Component } from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import withSizes from 'react-sizes';
import chatApi from '../../api/ChatApi';
import chatMessageApi from '../../api/ChatMessageApi';
import notificationApi from '../../api/NotificationApi';
import personLinkApi from '../../api/PersonLinkApi';
import userLinkApi from '../../api/UserLinkApi';
import {
    Chat,
    ChatMember,
    ChatMessage,
    Locale,
    Notification,
    PersonLink,
    PublicUser,
    ScreenSizeProps,
    User,
    UserLink,
    VideoCall,
} from '../../model/model';
import authService from '../../service/AuthService';
import CustomContext from '../../service/CustomContext';
import errorService from '../../service/ErrorService';
import eventService from '../../service/EventService';
import i18nService from '../../service/I18nService';
import nameService from '../../service/NameService';
import pathService from '../../service/PathService';
import responsiveService from '../../service/ResponsiveService';
import userService from '../../service/UserService';
import videoService from '../../service/VideoService';
import styles from './Header.module.scss';

class Header extends Component<Props, State> {
    static contextType = CustomContext;
    context!: React.ContextType<typeof CustomContext>;

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        try {
            this.init();
        } catch (error) {
            errorService.displayMessage(error);
        }
    }

    componentWillUnmount() {
        eventService.unsubscribe('notificationAdded');
        eventService.unsubscribe('notificationsRead');
        eventService.unsubscribe('chatMessageReceived');
        eventService.unsubscribe('chatRead');
        eventService.unsubscribe('videoCall');
    }

    /** METHODS **/

    init = async () => {
        if (this.context.user && this.state.notifications === undefined) {
            await this.countNotifications();
            await this.countMessages();
            eventService.subscribeToNotificationAdded(this.updateNotificationsAdded);
            eventService.subscribeToNotificationsRead(this.updateNotifications);
            eventService.subscribeToChatMessageReceived(this.updateMessagesReceived);
            eventService.subscribeToChatRead(this.updateMessages);
            eventService.subscribeToVideoCall(this.showIncomingCall);
        }
    };

    updateNotificationsAdded = async (notification: Notification) => {
        await this.countNotifications();
        if (notification.id) {
            this.showNotification(notification);
        }
    };

    updateNotifications = async () => {
        await this.countNotifications();
    };

    updateMessagesReceived = async (message: ChatMessage) => {
        await this.countMessages();
        await this.showMessage(message);
    };

    updateMessages = async (chat: Chat) => {
        await this.countMessages();
    };

    countNotifications = async () => {
        const notifications = await notificationApi.count();
        this.setState({ notifications });
    };

    countMessages = async () => {
        const messages = await chatMessageApi.count();
        this.setState({ messages });
    };

    showNotification = async (notification: Notification) => {
        switch (notification.notificationType) {
            case 'PERSON_LINK_INVITED':
                this.showPersonLinkInvitedNotification(notification);
                break;
            case 'PERSON_LINK_APPROVED':
                this.showPersonLinkApprovedNotification(notification);
                break;
            case 'USER_LINK_INVITED':
                this.showUserLinkInvitedNotification(notification);
                break;
            case 'USER_LINK_APPROVED':
                this.showUserLinkApprovedNotification(notification);
                break;
            default:
                break;
        }
    };

    showPersonLinkInvitedNotification = async (notification: Notification) => {
        notification.target = await personLinkApi.get(notification.targetId as number);
        const personLink = notification.target as PersonLink;
        const inviter = personLink.inviter as PublicUser;
        const title = i18nService.intl.formatMessage({ id: 'header.notifications.title' });
        const descripiton = i18nService.intl.formatMessage(
            { id: 'notifications.personLink.invited' },
            { user: inviter.fullName },
        );
        antdNotification['info']({
            message: title,
            description: descripiton,
            className: styles.notification,
            onClick: () => {
                this.props.history.push(`/links/approvals/${personLink.id}`);
                antdNotification.destroy();
            },
        });
    };

    showPersonLinkApprovedNotification = async (notification: Notification) => {
        notification.target = await personLinkApi.get(notification.targetId as number);
        const personLink = notification.target as PersonLink;
        const correspondingUser = nameService.getCorrespondingUserName(personLink, this.context.user as User);
        const title = i18nService.intl.formatMessage({ id: 'header.notifications.title' });
        const descripiton = i18nService.intl.formatMessage(
            { id: 'notifications.personLink.approved' },
            { user: correspondingUser },
        );
        antdNotification['info']({
            message: title,
            description: descripiton,
            className: styles.notification,
            onClick: () => {
                this.props.history.push(`/persons/${personLink.personId}/links/${personLink.id}`);
                antdNotification.destroy();
            },
        });
    };

    showUserLinkInvitedNotification = async (notification: Notification) => {
        notification.target = await userLinkApi.get(notification.targetId as number);
        const userLink = notification.target as UserLink;
        const inviter = userLink.inviter as PublicUser;
        const title = i18nService.intl.formatMessage({ id: 'header.notifications.title' });
        const descripiton = i18nService.intl.formatMessage(
            { id: 'notifications.userLink.invited' },
            { user: inviter.fullName },
        );
        antdNotification['info']({
            message: title,
            description: descripiton,
            className: styles.notification,
            onClick: () => {
                this.props.history.push(`/employees/approvals/${userLink.id}`);
                antdNotification.destroy();
            },
        });
    };

    showUserLinkApprovedNotification = async (notification: Notification) => {
        notification.target = await userLinkApi.get(notification.targetId as number);
        const userLink = notification.target as UserLink;
        const approver = userLink.approver as PublicUser;
        const title = i18nService.intl.formatMessage({ id: 'header.notifications.title' });
        const descripiton = i18nService.intl.formatMessage(
            { id: 'notifications.userLink.approved' },
            { user: approver.fullName },
        );
        antdNotification['info']({
            message: title,
            description: descripiton,
            className: styles.notification,
            onClick: () => {
                this.props.history.push(`/notifications`);
                antdNotification.destroy();
            },
        });
    };

    showMessage = async (message: ChatMessage) => {
        const chat = await chatApi.get(message.chatId);
        const sender = chat.members.find(m => m.id === message.memberId) as ChatMember;
        const receiver = chat.members.find(m => m.id !== message.memberId) as ChatMember;
        let description = message.body;
        if (description && description.length > 60) {
            description = description.substring(0, 60 - 3) + '...';
        }

        antdNotification['info']({
            message: sender.currentName,
            description: description,
            className: styles.notification,
            onClick: () => {
                this.props.history.push(`/persons/${receiver.personId}/chats/${message.chatId}`);
                antdNotification.destroy();
            },
        });
    };

    showIncomingCall = async (videoCall: VideoCall) => {
        const key = 'incomingCall';
        const chat = await chatApi.get(videoCall.chatId);
        const caller = chat.members.find(m => m.personId !== videoCall.personId) as ChatMember;
        const description = i18nService.intl.formatMessage({ id: 'notifications.incomingCall.description' });
        const reply = i18nService.intl.formatMessage({ id: 'notifications.incomingCall.reply' });
        const reject = i18nService.intl.formatMessage({ id: 'notifications.incomingCall.reject' });

        antdNotification.close(key);
        antdNotification['info']({
            key,
            icon: <Icon type="phone" className={styles.phone} theme="twoTone" twoToneColor="#1aaa55" rotate={90} />,
            message: caller.currentName,
            description: (
                <>
                    <p>{description}</p>
                    <p>
                        <Button
                            type="primary"
                            onClick={() => {
                                this.props.history.push(`/persons/${videoCall.personId}/chats/${videoCall.chatId}`, {
                                    videoCall,
                                });
                                antdNotification.destroy();
                            }}
                        >
                            {reply}
                        </Button>
                        <Button
                            type="danger"
                            onClick={() => {
                                videoService.reject(videoCall.videoMeeting.Meeting.MeetingId);
                                antdNotification.destroy();
                            }}
                        >
                            {reject}
                        </Button>
                    </p>
                </>
            ),
            className: `${styles.notification} ${styles.call}`,
            duration: 0,
        });
    };

    signOut = async () => {
        try {
            authService.signOut('signout');
        } catch (error) {
            errorService.displayMessage(error);
        }
    };

    /** HANDLERS **/

    handleSignOut = () => {
        this.signOut();
    };

    handleChangeLocale = async (locale: Locale) => {
        await userService.setSelectedLocale(locale);
        window.location.href = pathService.getAbsolutePath(this.props.location.pathname, locale);
    };

    /** COMPONENTS **/

    renderUserSubmenu = (): JSX.Element => {
        const user = this.context.user as User;
        return (
            <Menu.SubMenu
                title={
                    <>
                        <Icon type="user" /> <FormattedMessage id="header.user" />
                    </>
                }
                key="settings"
                data-test="settings"
            >
                <Menu.Item key="user" hidden={!authService.isUserComplete()} data-test="user">
                    <Link to="/user">
                        <Icon type="user" />
                        <FormattedMessage id="header.profile" />
                    </Link>
                </Menu.Item>
                <Menu.Item key="subscriptions" data-test="subscriptions">
                    <Link to="/subscriptions">
                        <Icon type="credit-card" />
                        <FormattedMessage id="header.subscriptions" />
                    </Link>
                </Menu.Item>
                <Menu.Item key="bankDetails" data-test="bankDetails" hidden={!user.associate}>
                    <Link to="/bank-details">
                        <Icon type="bank" />
                        <FormattedMessage id="header.bankDetails" />
                    </Link>
                </Menu.Item>
                <Menu.Item key="invoices" data-test="invoices">
                    <Link to="/invoices">
                        <Icon type="audit" />
                        <FormattedMessage id="header.invoices" />
                    </Link>
                </Menu.Item>
            </Menu.SubMenu>
        );
    };

    renderToolsSubmenu = (): JSX.Element => {
        return (
            <Menu.Item key="tools">
                <Link to={pathService.getPath('tools')}>
                    <Icon type="tool" />
                    <FormattedMessage id="header.tools" />
                </Link>
            </Menu.Item>
        );
    };

    renderLanguagesSubmenu = (): JSX.Element => {
        return (
            <Menu.SubMenu
                key="34"
                title={
                    <>
                        <Icon type="global" /> <FormattedMessage id="header.languages" />
                    </>
                }
                className={styles.languages}
                data-test="languages"
            >
                <Menu.Item key="en">
                    <Button type="link" onClick={() => this.handleChangeLocale('en')} data-test="en">
                        English
                    </Button>
                </Menu.Item>
                <Menu.Item key="es">
                    <Button type="link" onClick={() => this.handleChangeLocale('es')} data-test="es">
                        Español
                    </Button>
                </Menu.Item>
                {/* <Menu.Item key="it">
                    <Button type="link" onClick={() => this.handleChangeLocale('it')}>Italiano</Button>
                </Menu.Item>
                <Menu.Item key="pl">
                    <Button type="link" onClick={() => this.handleChangeLocale('pl')}>Polski</Button>
                </Menu.Item> */}
            </Menu.SubMenu>
        );
    };

    renderContactSubmenu = (): JSX.Element => {
        return (
            <Menu.Item key="contact" data-test="contact">
                <Link to={pathService.getPath('contact-us')}>
                    <Icon type="info-circle" />
                    <FormattedMessage id="header.contact" />
                </Link>
            </Menu.Item>
        );
    };

    renderHelpSubmenu = (): JSX.Element => {
        return (
            <Menu.Item key="help" data-test="help">
                <Link to={pathService.getPath('contact-us')}>
                    <Icon type="info-circle" />
                    <FormattedMessage id="header.help" />
                </Link>
            </Menu.Item>
        );
    };

    renderHeaderMenu = (): JSX.Element => {
        if (this.context.user) {
            const notifications = this.state.notifications || 0;
            return (
                <Menu theme="dark" mode="horizontal" className={styles.menu}>
                    <Menu.Item key="dashboard" hidden={this.props.isXs} data-test="dashboard">
                        <Link to="/dashboard">
                            <Icon type="home" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="persons" hidden={!authService.isUserComplete()} data-test="persons">
                        <Link to="/persons">
                            <Icon type="team" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="notifications" hidden={!authService.isUserComplete()} data-test="notifications">
                        <Link to="/notifications">
                            <Badge count={notifications} dot>
                                <Icon type="bell" />
                            </Badge>
                        </Link>
                    </Menu.Item>

                    <Menu.SubMenu key="menu" title={<Icon type="menu" />} data-test="submenu">
                        {authService.isUserComplete() && this.renderUserSubmenu()}
                        <Menu.Item key="calendar" hidden={!authService.isUserComplete()} data-test="calendar">
                            <Link to="/calendar">
                                <Icon type="calendar" />
                                <FormattedMessage id="header.calendar" />
                            </Link>
                        </Menu.Item>
                        <Menu.Item
                            key="userLinks"
                            hidden={!authService.isUserComplete() || this.context.user.userType === 'PATIENT'}
                            data-test="userLinks"
                        >
                            <Link to="/employees">
                                <Icon type="bank" />
                                <FormattedMessage id="header.userLinks" />
                            </Link>
                        </Menu.Item>
                        {this.renderLanguagesSubmenu()}
                        {this.renderHelpSubmenu()}
                        <Menu.Item key="logout" onClick={this.handleSignOut} data-test="signout">
                            <Icon type="logout" />
                            <FormattedMessage id="header.signout" />
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
            );
        } else if (this.context.user === null) {
            return (
                <Menu theme="dark" mode="horizontal" className={styles.menu}>
                    <Menu.Item key="prices" data-test="prices">
                        <Link to={pathService.getPath('prices')}>
                            <FormattedMessage id="header.prices" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="signin" data-test="signin">
                        <Link to="/signin">
                            <FormattedMessage id="header.signin" />
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="menu" title={<Icon type="menu" />}>
                        {this.renderToolsSubmenu()}
                        {this.renderContactSubmenu()}
                        {this.renderLanguagesSubmenu()}
                    </Menu.SubMenu>
                </Menu>
            );
        } else {
            return <></>;
        }
    };

    render() {
        const homeLink: string = this.context.user ? '/dashboard' : '/';
        return (
            <div className={styles.header}>
                <Link to={homeLink} className={styles.logo}>
                    <span className={styles.icon}>
                        <img src="/images/logo.png" alt="SecuHealth" />
                    </span>
                    <span hidden={this.props.isXs || this.props.isSm}>SecuHealth</span>
                </Link>
                {this.renderHeaderMenu()}
            </div>
        );
    }
}
export default withSizes(responsiveService.mapSizesToProps)(injectIntl(withRouter(Header)));

interface Props extends RouteComponentProps, WrappedComponentProps, ScreenSizeProps {}

interface State {
    notifications?: number;
    messages?: number;
}
